<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="users" dense :search="search">
        <template v-slot:item.action="{ item }">
          <v-btn icon small tile class="mr-2">
            <v-icon @click="loadUserGroups(item)">mdi-account-group</v-icon>
          </v-btn>
          <v-btn icon small tile class="mr-2">
            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small tile class="mr-2">
            <v-icon @click="openDialogPassword(item)">mdi-key</v-icon>
          </v-btn>
          <v-btn icon small tile class="mr-2">
            <v-icon @click="remove(item.id)" color="red">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog
      v-model="dialog"
      :title="!edit ? 'Inserir Usuário' : 'Alterar Usuário'"
      icon="mdi-account"
      @close-dialog="loadData"
    >
      <template>
        <form>
          <v-row>
            <v-col>
              <b>Nome *</b>
              <v-text-field
                v-model="user.username"
                outlined
                tile
                dense
                class="my-2"
                :error="error"
                :rules="[requiredRule, spaceRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>E-mail *</b>
              <v-text-field
                v-model="user.email"
                outlined
                tile
                dense
                class="my-2"
                :error="error"
                :rules="[requiredRule, validarEmail, spaceRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!edit">
            <v-col>
              <b>Senha *</b>
              <v-text-field
                v-model="user.password"
                outlined
                tile
                dense
                :rules="[requiredRule, passwordRule]"
                class="my-2"
                :error="error"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'" 
                @click:append="showPassword = !showPassword"
                v-if="!edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Empresa *</b>
              <v-select
                v-model="user.idCompany"
                :items="companys"
                item-text="name"
                item-value="id"
                dense
                outlined
                :rules="[requiredRule, spaceRule]"
                class="my-2"
              ></v-select>
            </v-col>
          </v-row>
        </form>
      </template>
      <template v-slot.actions>
        <v-btn
          color="primary"
          depressed
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Alterar </span>
        </v-btn>
      </template>
    </TheDialog>

    <TheDialog
      v-model="dialogPassword"
      :title="!edit ? 'Inserir Usuário' : 'Alterar Usuário'"
      icon="mdi-account"
      @close-dialog="loadData"
    >
      <template>
        <form>
          <v-row>
            <v-col>
              <b>Senha</b>
              <v-text-field
                v-model="user.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'" 
                @click:append="showPassword = !showPassword"
                :rules="[requiredRule, passwordRule]"
                color="primary" 
                prepend-inner-icon="mdi-key" 
                outlined
                tile
                css="my-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </form>
      </template>
      <template v-slot.actions>
        <v-btn
          color="primary"
          depressed
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Alterar </span>
        </v-btn>
      </template>
    </TheDialog>

    <TheDialog
      v-model="dialogGroups"
      title="Vincular Grupos em Usuário"
      icon="mdi-group"
    >
      <template>
        <v-simple-table class="mb-5" v-if="userSelected">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="20%">Grupos</th>
                <th class="text-center">
                  {{ userSelected.username }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in groups" :key="item.id">
                <td width="20%">{{ item.name }}</td>
                <td class="text-center">
                  <v-btn
                    depressed
                    dense
                    :color="getColor(item)"
                    @click="bindUserGroup(item)"
                  >
                    <span v-if="!checkUserGroup(item)">Vincular</span>
                    <span v-if="checkUserGroup(item)">Remover</span>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
          width="100%"
        ></v-progress-linear>
      </template>
    </TheDialog>
    
  </v-container>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import TheDialog from "@/components/TheDialog";
// import TheInput from "@/components/TheInput";
import TheTitle from "@/components/TheTitle";

export default {
  name: "Users",
  components: {
    TheDialog,
    // TheInput,
    TheTitle,
  },
  data: () => ({
    title: "Usuários",
    pageIcon: "mdi-account",
    search: null,
    showPassword: false,
    dialog: false,
    dialogGroups: false,
    dialogPassword: false,
    user: {},
    header: [
      { text: "Codigo", value: "id", width: "10%" },
      { text: "Nome", value: "username" },
      { text: "Email", value: "email" },
      { text: "Ações", value: "action", width: "20%", align: "center" },
    ],
    users: [],
    error: false,
    edit: false,
    companys: [],
    groups: [],
    userSelected: {},
    loading: false,
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/user`;
      axios
        .get(url)
        .then((res) => {
          this.users = res.data;
        })
        .catch(showError)
        .finally(() => {
          this.loading = false;
        });
    },
    loadCompany() {
      const url = `${baseApiUrl}/company`;
      axios
        .get(url)
        .then((res) => {
          this.companys = res.data;
        })
        .catch(showError)
        .finally(() => {
          this.loading = false;
        });
    },
    loadGroups() {
      const url = `${baseApiUrl}/group`;
      axios
        .get(url)
        .then((res) => {
          this.groups = res.data;
          this.loading = false;
        })
        .catch(showError);
    },
    loadUserGroups(item) {
      const url = `${baseApiUrl}/user/${item.id}/group`;
      axios
        .get(url)
        .then((res) => {
          item.groups = res.data;
          this.userSelected = item;
          this.dialogGroups = true;
          this.loadGroups();
        })
        .catch(showError);
    },
    dialogSave() {
      this.user = {};
      this.edit = false;
      this.dialog = true;
    },
    dialogUpdate(item) {
      this.user = item;
      delete this.user.password;
      this.user.acceptTerms == 1 ? this.user.acceptTerms = true : this.user.acceptTerms = false 
      this.user.acceptProducts == 1 ? this.user.acceptProducts = true : this.user.acceptProducts = false 
      this.user.acceptNews == 1 ? this.user.acceptNews = true : this.user.acceptNews = false 
      this.edit = true;
      this.dialog = true;
      console.log(this.user);
    },
    openDialogPassword(item) {
      this.user = item;
      delete this.user.email;
      delete this.user.username;
      delete this.user.password;
      this.edit = true;
      this.dialogPassword = true;
    },
    resolveForm() {
      if (this.edit) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.error = false;
      if (!this.user.email || !this.user.password) {
        return (this.error = true);
      }
      const url = `${baseApiUrl}/user`;
      const user = this.user;
      console.log(user);
      axios
        .post(url, user)
        .then(() => {
          this.loadData();
          this.dialog = false;
        })
        .catch(showError)
        .finally(() => {
          this.loading = false;
          this.error = true;
        });
    },
    update() {
      this.error = false;
      const id = this.user.id;
      const url = `${baseApiUrl}/user/${id}`;
      const users = this.user;
      delete users.id;
      delete users.groups;
      delete users.birthday;
      delete users.createdAt;
      delete users.updatedAt;
      delete users.deletedAt;
      console.log(users);
      axios
        .put(url, users)
        .then(() => {
          this.dialog = false;
          this.dialogPassword = false;
          this.loadData();
        })
        .catch(showError)
        .finally(() => {
          this.error = true;
        });
    },
    remove(id) {
      if (confirm("Deseja Excluir?")) {
        const url = `${baseApiUrl}/user/${id}`;
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError);
      }
    },
    getColor(group) {
      let color = "primary";
      if (this.checkUserGroup(group)) {
        color = "grey";
      }
      return color;
    },
    bindUserGroup(group) {
      const id = this.userSelected.id;
      const url = `${baseApiUrl}/user/${id}/group`;
      const userGroup = {
        idUser: this.userSelected.id,
        idGroup: group.id,
      };
      this.loading = true;
      if (this.checkUserGroup(group)) {
        axios
          .delete(`${url}/${group.id}`)
          .then(() => {
            this.loadUserGroups(this.userSelected);
          })
          .catch(showError)
          .finally();
      } else {
        axios
          .post(url, userGroup)
          .catch(showError)
          .finally(() => {
            this.loadUserGroups(this.userSelected);
            this.loadGroups();
            this.loading = false;
          });
      }
    },
    checkUserGroup(group) {
      let ret = false;
      this.userSelected.groups.forEach((element) => {
        if (element.idGroup === group.id) {
          ret = true;
        }
      });
      return ret;
    },
  },
  mounted() {
    this.loadData();
    this.loadGroups();
    this.loadCompany();
  },
};
</script>

<style>
.full-height {
  height: 80vh;
}
</style>